@import '../../assets/scss/invisible.scss';

.weightTicker {
  font-weight: 700;
  font-variant-numeric: tabular-nums;
  display: flex;
}

.character {
  font-size: rem(90px);
  line-height: rem(160px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(30px);
  height: rem(241px);
  margin-left: rem(12px);
  padding-top: rem(58px);
  padding-right: rem(3px);
}

.box {
  font-size: rem(160px);
  width: rem(164px);
  border-radius: $border-radius-lg;
  background-color: $gray-600;
  margin-left: rem(10px);
  margin-right: rem(9px);
  padding: 0;
  position: relative;
  overflow: hidden;

  .fade {
    position: absolute;
    left: 0;
    right: 0;
    height: rem(30px);
    z-index: 1;
  }
}

.fadeTop {
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba($gray-600, 1) 0%,
    rgba($gray-600, 0.94) 30%,
    rgba($gray-600, 0) 100%
  );
}

.fadeBottom {
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba($gray-600, 1) 0%,
    rgba($gray-600, 0.94) 30%,
    rgba($gray-600, 0) 100%
  );
}
