@import '../../../assets/scss/invisible.scss';

.board {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: rem(540px);
}

.primary {
  margin-bottom: rem(40px);
  flex-grow: 0;
}

.secondary {
  flex-grow: 1;
  display: flex;
  min-height: rem(500px);
}
