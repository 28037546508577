@import '../../assets/scss/invisible.scss';

.nextChallengeIndicator {
  width: 100%;
}

.title {
  font-size: rem(70px);
  font-weight: 700;
  margin-bottom: rem(44px);
  margin-left: rem(50px);
  overflow: hidden;
  will-change: transform;
}

.highlight {
  color: $red;
}

.main {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: rem(50px) rem(44px) rem(50px) rem(50px);
  background: $secondary-gradient;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.ticker {
  position: relative;
  height: rem(241px);
  width: rem(1454px);
  overflow: hidden;
  contain: content;
}

.wording {
  position: absolute;
  padding-bottom: rem(15px);
  will-change: transform;
}
