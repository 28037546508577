@import '../../../assets/scss/invisible.scss';

.secondaryBoardInfoLine {
  display: flex;
  font-size: rem(30px);
}

.change {
  width: rem(17px);
  height: rem(17px);
  margin-right: rem(14px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $yellow;
  flex-shrink: 0;
  align-self: center;
  position: relative;
  bottom: rem(6px);

  &.up {
    color: $green;
  }

  &.down {
    color: $red;
    transform: rotate(180deg);
  }
}

.rank {
  font-weight: 500;
}

.nameScroller {
  margin-right: 10px;
  font-weight: 700;
  width: rem(250px);
  -webkit-mask-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) rem(10px),
    rgba(0, 0, 0, 1) rem(15px),
    rgba(0, 0, 0, 1) rem(235px),
    rgba(0, 0, 0, 0)
  );
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.nameHolder {
  white-space: nowrap;
}

.name {
  margin-left: rem(15px);
  margin-right: rem(20px);
}

.liftInfo {
  font-size: rem(25px);
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  padding-top: rem(4px);
  margin-left: auto;
}

.amountInfo {
  margin-bottom: rem(5px);
}

.repSetInfo {
  font-size: rem(12px);
}

.unit {
  text-transform: none;
}
