@import '../../assets/scss/invisible.scss';

.celebrationOverlay {
  position: absolute;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 3%;
  background-color: rgba($black, 0.8);
  overflow: hidden;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .celebrationOverlay {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: rgba($black, 0.4);
  }
}
