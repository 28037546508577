@import '../../../assets/scss/invisible.scss';

.contributorBoxSecondary {
  display: flex;
  flex-flow: column;
  background: $primary-gradient;
  color: $white;
  padding: rem(35px) rem(60px) rem(28px);
}

.subTitle {
  font-size: rem(30px);
  font-weight: 300;
}

.weightHolder {
  font-size: rem(120px); // max use-text-fit will use
  line-height: 0.85;
  font-weight: 700;
  white-space: nowrap;
  height: rem(144px);
  width: 100%;
  margin-top: rem(-24px);
}

.weight {
  height: inherit;
  width: 100%;
  align-items: center;
}
