@import '../../../assets/scss/invisible.scss';

.milestoneProgress {
  width: 100%;
  position: relative;
  padding-left: rem(196px);
}

.positioner {
  height: rem(120px);
}

.parts {
  display: inline-flex;
}

.part {
  text-align: right;
  position: relative;

  &:first-child {
    width: 0;
  }
}

.progressContainer {
  text-align: left;
}

.bar {
  height: rem(29px);
  border-top-right-radius: rem(15px);
  border-bottom-right-radius: rem(15px);
  margin-top: rem(16px);

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: $blood;
    border-radius: rem(29px);
  }
}

.point {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
