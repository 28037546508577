@import '../../../assets/scss/invisible.scss';

.boardList {
  display: flex;
  margin: 0 rem(-25px);
  will-change: opacity, transform;
}

.board {
  padding-left: rem(25px);
  padding-right: rem(25px);
  will-change: transform;
}
