@import '../../../assets/scss/invisible.scss';

.milestonePoint {
  display: inline-flex;
  flex-direction: column;
}

.centered {
  align-items: center;
}

.inner {
  &.centered {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    left: 50%;
  }
}

.point {
  width: rem(62px);
  height: rem(62px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-gradient;
  box-shadow: $box-shadow;
  border-radius: 50%;
  margin-bottom: rem(9px);
}

.icon {
  width: rem(32px);
  height: rem(36px);
  opacity: 0;
}

.text {
  font-size: rem(40px);
  font-weight: 700;
  opacity: 0.5;
  white-space: nowrap;
}
