@import '../../../assets/scss/invisible.scss';

.prizeInfo {
  margin-bottom: rem(20px);
}

.point {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
