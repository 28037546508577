@import-normalize;

@import 'fonts/barlow';
@import 'vendor';
@import 'invisible';
@import 'reboot';

#root {
  overflow: hidden; // Prevent any scrolling.
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;

  background-image: url(../images/bg-carousel.png);
  background-repeat: no-repeat;
  background-size: cover;
}
