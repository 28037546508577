@import '../../../assets/scss/invisible.scss';

.tab {
  height: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  overflow: hidden;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
