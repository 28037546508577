@import '../../assets/scss/invisible.scss';

.challengeSummary {
  width: 100%;
}

.title {
  font-weight: 400;
  font-size: rem(70px);
  margin-bottom: rem(36px);
  margin-left: rem(48px);
  display: flex;
  overflow: hidden;
}

.contributors {
  display: inline-flex;
}

.main {
  position: relative;
  z-index: 1;
}

.period {
  display: flex;
  justify-content: space-between;
  margin-top: rem(32px);
  margin-right: rem(332px);
  margin-left: rem(50px);
  font-size: rem(50px);
  font-weight: 400;
}

.highlight {
  color: $red;
  font-weight: 700;
}

.grammar {
  position: relative;
  white-space: nowrap;
}

.grammarTerm {
  position: absolute;
  display: block;
}
