@import '../../../assets/scss/invisible.scss';

.shoutOutBoxPrimaryInfo {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  font-weight: 800;
  text-align: center;
}

.titleHolder {
  font-size: rem(86px); // max use-text-fit will use
  font-weight: 800;
  line-height: 0.85;
  width: 100%;
  text-align: center;
  margin-top: rem(-10px);
}

.title {
  height: rem(100px);
  width: 100%;
  align-items: flex-end;
  justify-content: center;

  .highlight {
    white-space: nowrap;
  }
}

.titlePart {
  justify-content: center;
  margin-top: rem(30px);
}

.highlight {
  color: $red-700;
}

.bigValHolder {
  font-size: rem(200px); // max use-text-fit will use
  line-height: 0.85;
  white-space: nowrap;
  height: rem(240px);
  width: 100%;
}

.bigVal {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.summary {
  font-size: rem(96px);
}
