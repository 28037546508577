@import '../../assets/scss/invisible.scss';

.weightAchieved {
  position: relative;
  z-index: 2;
}

.inner {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: rem(50px) rem(40px) rem(50px) rem(40px);
  background: $secondary-gradient;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.unit {
  font-size: rem(160px);
  line-height: rem(194px);
  font-weight: 700;
  color: rgba($white, 0.15);
}

.character {
  font-size: rem(90px);
  line-height: rem(194px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(30px);
  height: rem(241px);
  margin-left: rem(-3px);
  margin-right: rem(-10px);
  padding-top: rem(58px);
  padding-right: rem(3px);
}

.box {
  font-size: rem(160px);
  width: rem(164px);
  border-radius: $border-radius-lg;
  background-color: $gray-600;
  margin-left: rem(10px);
  margin-right: rem(9px);
  padding: 0;
}

.text {
  text-shadow: 0 rem(24px) rem(32px) rgba($black, 0.37);
  background-image: linear-gradient(180deg, $gray-300 0%, $white 47%, $gray-300 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
