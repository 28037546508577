@import '../../../assets/scss/invisible.scss';

.primaryBoardInfo {
  box-shadow: $box-shadow;
  background: $primary-gradient;
  border-radius: $border-radius;
  font-weight: 700;
  padding: rem(5px) rem(50px) rem(40px);
}

.title {
  display: flex;
  align-items: flex-end;
  flex: 1 0 rem(110px);
  min-height: rem(110px);
  margin-bottom: rem(25px);
}

.rank {
  display: flex;
  align-items: baseline;
  margin-right: rem(10px);
}

.rankSmall {
  font-size: rem(41px);
}

.rankBig {
  font-size: rem(73px);
  line-height: rem(41px);
}

.wording {
  display: flex;
  flex-direction: column;
}

.best {
  font-size: rem(20px);
}

.type {
  font-size: rem(40px);
}

.lifterInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameHolder {
  height: rem(122px);
  width: rem(230px);
  font-size: rem(55px); // max use-text-fit will use
  line-height: 0.95;
}

.name {
  width: 100%;
  height: 100%;
  align-items: center;
}

.liftInfo {
  text-align: right;
}

.amountInfo {
  font-size: rem(45px);
  font-weight: 300;
  margin-bottom: rem(10px);
}

.repSetInfo {
  font-size: rem(40px);
  font-weight: 100;
}

.unit {
  text-transform: none;
}
