@import '../../../assets/scss/invisible.scss';

.timer {
  width: 100%;
  height: rem(16px);
  position: relative;
  background-color: $gray-500;
  contain: content;
}

.value {
  position: absolute;
  height: 100%;
  width: 100%;
  background: $primary-gradient;

  opacity: 0;
  transform: scaleX(0%);
  transform-origin: right;

  will-change: transform, opacity;
}

.animate {
  // should have duration and delay applied dynamically
  animation-name: progressIn, progressOut;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: 1, 1;
  animation-fill-mode: forwards;
}

@keyframes progressIn {
  from {
    opacity: 1;
    transform: scaleX(0%);
    transform-origin: left;
  }

  to {
    opacity: 1;
    transform: scaleX(100%);
    transform-origin: left;
  }
}

@keyframes progressOut {
  from {
    opacity: 1;
    transform: scaleX(100%);
    transform-origin: right;
  }

  to {
    opacity: 0;
    transform: scaleX(0%);
    transform-origin: right;
  }
}
