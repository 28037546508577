@import '../../../../assets/scss/invisible.scss';

.centralContentWrapper {
  display: flex;
  position: absolute;
  width: 100%;
  top: rem(277px);
  padding-left: rem(52px);
  padding-right: 12.25rem;
}

.challengeSummaryWrapper {
  width: 100%;
}

.percentageIndicatorWrapper {
  position: absolute;
  right: rem(61px);
  top: rem(50px);
}

.milestoneProgressWrapper {
  display: flex;
  position: absolute;
  bottom: rem(70px);
  width: 80%;
  overflow: hidden;
}
