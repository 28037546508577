@import '../../../assets/scss/invisible.scss';

.prizeCard {
  font-weight: 700;
}

.prizeImageHolder {
  width: 100%;
  height: rem(430px);
  margin-bottom: rem(30px);
  position: relative;
  left: rem(-7px);
  border-radius: $border-radius-lg;
  border: rem(7px) solid transparent;

  .achieved & {
    border-color: $red;
  }
}

.prizeImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-lg;
  opacity: 0.35;

  .achieved & {
    border-radius: 0;
    opacity: 1;
  }
}

.prizeText {
  opacity: 0.5;

  .achieved & {
    opacity: 1;
  }
}

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  font-size: rem(52px);
  margin-bottom: rem(8px);

  &:last-child {
    padding-bottom: rem(28px);
  }
}

.additionalText {
  font-size: rem(20px);
}
