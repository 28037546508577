@import '../../../assets/scss/invisible.scss';

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.logo {
  position: absolute;
  bottom: rem(62px);
  right: rem(82px);
  width: rem(149px);
  height: rem(69px);
}
