@import '../../assets/scss/invisible.scss';

.percentageIndicator {
  width: rem(464px);
  height: rem(464px);
  background: $primary-gradient;
  box-shadow: $box-shadow;
  border-radius: 50%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radial {
  position: absolute;
  width: rem(407px);
}

.progressRing {
  width: 100%;
}

.circle {
  stroke: rgba($white, 0.14);
  stroke-width: 7;
}

.trail {
  stroke: $white;
  stroke-width: 7;
  stroke-linecap: round;
  // axis compensation
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.textual {
  margin-top: rem(-4px);
}

.top {
  font-weight: 100;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-variant-numeric: tabular-nums;
}

.large {
  font-size: rem(160px);
  display: flex;
  margin-bottom: 5px;
  overflow: hidden;
}

.small {
  font-size: rem(60px);
}

.bottom {
  font-weight: 900;
  font-size: rem(31px);
  letter-spacing: rem(5.83px);
  text-align: center;
}
