@import '../../../assets/scss/invisible.scss';

.contributorSlide {
  display: flex;
  margin-left: rem(-25px);
  margin-right: rem(-25px);
}

.contributorItem {
  padding-left: rem(25px);
  padding-right: rem(25px);
  flex-basis: 33.3333%;
  min-width: 33.3333%;
}
