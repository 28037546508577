@import '../../../assets/scss/invisible.scss';

.slider {
  position: relative;
}

.title {
  font-size: rem(70px);
  font-weight: 700;
  margin-bottom: rem(42px);
}

.highlight {
  color: $red;
}

.contributorSlideItem {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
