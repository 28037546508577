@import '../../../assets/scss/invisible.scss';

.contributorBoxPrimary {
  display: flex;
  flex-flow: column;
  padding: rem(2px) rem(60px) rem(24px);
  background: $secondary-gradient;
}

.titleHolder {
  display: flex;
  align-items: flex-end;
  height: rem(100px);
  margin-bottom: rem(23px);
}

.title {
  font-size: rem(40px);
  font-weight: 300;
  margin-right: rem(20px);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.decoration {
  min-height: 1px;
  height: rem(3px);
  background-color: $redish;
  flex: 1 0 15%;
  position: relative;
  bottom: rem(20px);
}

.nameHolder {
  font-size: rem(80px); // max use-text-fit will use
  line-height: 0.93;
  font-weight: 700;
  height: rem(170px);
  width: 100%;
}

.name {
  height: inherit;
  width: 100%;
  align-items: center;
}
