@import '../../../assets/scss/invisible.scss';

.secondaryBoardInfo {
  box-shadow: $box-shadow;
  background: $secondary-gradient;
  border-radius: $border-radius;
  font-weight: 700;
  padding: rem(45px) rem(50px);
  min-height: 100%;
  width: 100%;
}

.score {
  margin-bottom: rem(25px);
}
