@import '../../../assets/scss/invisible.scss';

.tabNavigation {
  position: absolute;
  top: rem(92px);
  right: rem(100px);
  font-size: rem(40px);
  margin-left: rem(-2px);
  margin-right: rem(-2px);
}

.list {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 rem(-50px);
  list-style: none;
  margin-bottom: rem(6px);
}

.listItem {
  padding-left: rem(50px);
  padding-right: rem(50px);
  font-weight: 300;
}

.listItemText {
  display: block;
}

.active {
  font-weight: 700;
}

.underbar {
  height: rem(8px);
  background-color: rgba($white, 0.11);
}

.highlight {
  position: relative;
  background-color: $red;
  height: 100%;
}
