@import '../../assets/scss/invisible.scss';

.challengeSummaryCountdown {
  position: relative;
  white-space: nowrap;
  overflow-y: hidden;
  flex-grow: 1;
}

.wording {
  position: absolute;
  right: 0;
}

.highlight {
  color: $red;
  font-weight: 700;
}
