@import '../../assets/scss/invisible.scss';

.weight-indicator {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: rem(50px) rem(355px) rem(40px) rem(50px);
  background: $secondary-gradient;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  overflow: hidden;
}

.unit {
  width: rem(120px);
  font-size: rem(100px);
  line-height: rem(194px);
  font-weight: 700;
  color: rgba($white, 0.15);
  margin-left: rem(8px);
}
