@import '../../../assets/scss/invisible.scss';

.prizeInfo {
  position: relative;
  will-change: opacity;
}

.background {
  background: $primary-gradient;
  position: absolute;
  top: rem(220px);
  right: rem(321px);
  bottom: 0;
  border-radius: $border-radius;
  width: rem(449px);
  height: rem(463px);

  will-change: transform;
}

.prizeImageHolder {
  position: absolute;
  right: rem(442px);
  top: rem(40px);
  z-index: 1;
  width: rem(1278px);
  height: rem(717px);
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  background-image: $secondary-gradient;
  contain: content;
  will-change: transform;
}

.prizeImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.foreground {
  display: inline-flex;
  flex-direction: column;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: $box-shadow;
  padding: rem(90px) rem(45px) rem(45px);
  font-size: rem(40px);
  font-weight: 300;
  flex-grow: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: rem(558px);
  min-height: rem(576px);
  contain: content;
  will-change: transform;
}

.foregroundBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: $secondary-gradient;
  z-index: -1;
  width: 100%;
}

.title {
  display: flex;
  flex-direction: column;
  font-size: rem(40px);
  font-weight: 300;
  margin-bottom: rem(40px);
}

.emphasis {
  font-weight: 700;
  font-style: normal;
}

.prefix {
  margin-bottom: rem(-16px);
}

.mainTitleHolder {
  width: 100%;
  height: rem(292px);
  font-size: rem(240px); // max use-text-fit will use
  line-height: 0.85;
}

.mainTitle {
  font-weight: 900;
  color: $red;
  align-items: center;
  height: rem(300px);
  width: 100%;
}
