@import '../../../assets/scss/invisible.scss';

.shoutOutBox {
  width: rem(558px);
  min-height: rem(576px);
  position: relative;
  display: flex;
  flex-flow: column;
}

.background {
  background: $primary-gradient;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: $border-radius;
}

.foreground {
  display: inline-flex;
  flex-direction: column;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: $box-shadow;
  padding: rem(92px) rem(42px) rem(47px) rem(47px);
  flex-grow: 1;
  position: relative;
  z-index: 2;
  height: 100%;
}

.foregroundBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: $secondary-gradient;
  z-index: -1;
}

.info {
  margin-top: rem(-10px);
}
