@import '../../../assets/scss/invisible.scss';

.milestonePrizeProgress {
  width: 100%;
  position: relative;
  border-radius: $border-radius;
  background: $secondary-gradient;
  box-shadow: $box-shadow;
  padding: rem(43px) rem(4px) rem(64px) rem(50px);
}

.positioner {
  height: rem(700px);
}

.parts {
  display: inline-flex;
}

.part {
  text-align: right;
  position: relative;
  z-index: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: rem(558px);

  will-change: transform, opacity;

  &.hidden {
    display: none;
  }
}

.progressContainer {
  text-align: left;
  position: absolute;
  right: calc(100% - rem(30px));
  width: 100%;
  bottom: rem(65px);
}

.bar {
  height: rem(29px);
  border-top-right-radius: rem(15px);
  border-bottom-right-radius: rem(15px);
  margin-top: rem(16px);

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: $blood;
    border-radius: rem(29px);
  }
}

.point {
  position: relative;
  z-index: 1;
  margin-top: auto;
  text-align: left;
  padding-right: rem(40px);
  width: 100%;
}
