@import '../../../../assets/scss/invisible.scss';

.shoutOutMain {
  position: absolute;
  top: rem(272px);
  left: rem(100px);
  width: 50%;
}

.shoutOutBox {
  position: absolute;
  top: rem(236px);
  right: rem(100px);
}
