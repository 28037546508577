@import '../../../../assets/scss/invisible.scss';

.boardList {
  position: absolute;
  bottom: 0;
  left: rem(100px);
}

.fromTitle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  will-change: opacity, transform;
}
