@import '../../assets/scss/invisible.scss';

.dateTimeTicker {
  font-weight: 700;
  font-variant-numeric: tabular-nums;
  display: flex;
}

.character {
  font-size: rem(160px);
  line-height: rem(160px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(164px);
  height: rem(241px);
  border-radius: $border-radius-lg;
  background-color: $gray-600;
  margin-left: rem(10px);
  margin-right: rem(9px);
  position: relative;
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.isSeparator {
  color: rgba($white, 0.4);
}

.fade {
  position: absolute;
  left: 0;
  right: 0;
  height: rem(30px);
  z-index: 1;
}

.fadeTop {
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba($gray-600, 1) 0%,
    rgba($gray-600, 0.94) 30%,
    rgba($gray-600, 0) 100%
  );
}

.fadeBottom {
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba($gray-600, 1) 0%,
    rgba($gray-600, 0.94) 30%,
    rgba($gray-600, 0) 100%
  );
}
