@import '../../assets/scss/invisible.scss';

.rollingNumber {
  overflow: hidden;
  font-variant-numeric: tabular-nums;
  display: flex;
  flex-direction: column;

  &.reverse {
    flex-direction: column-reverse;
  }
}

.digit {
  min-height: 100%;
}
