@import '../../../assets/scss/invisible.scss';

.logoHolder {
  position: absolute;
  left: rem(98px);
  top: rem(63px);
  width: rem(310px);
  height: rem(100px);
  display: flex;
  align-items: center;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}
