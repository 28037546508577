// Variables

// Colors
$white: #ffffff;
$gray-300: #878787;
$gray-500: #3f4151;
$gray-600: #363b49;
$gray-700: #292d3e;
$gray-800: #242837;
$gray-900: #212529;
$blue: #201d2c;
$black: #000000;
$blood: #b30025;
$red: #f20025;
$red-700: #e2001c;
$redish: #f30025;
$orange: #fd2b12;
$yellow: #ff9900;
$green: #3de160;

$primary: $red;
$secondary: $gray-600;

$primary-gradient: linear-gradient(225deg, $redish 0%, $blood 100%);
$secondary-gradient: linear-gradient(225deg, $gray-700 0%, $gray-800 100%);

// Body
//
// Settings for the `<body>` element.

$body-bg: $blue;
$body-color: $white;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.2;
$line-height-sm: 0.82;

$border-radius: 0.188rem;
$border-radius-lg: 0.438rem;
$box-shadow: 0 24px 32px rgba($black, 0.37);

// Typography

$font-family-sans-serif: Barlow, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-base: $font-family-sans-serif;

$font-weight-base: 700;
$line-height-base: 1;
