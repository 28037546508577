// Reboot
//
// Sets html/body styles and some global base styles we can inherit.

// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
  -webkit-tap-highlight-color: rgba($black, 0); // 5
  height: 100%;
  font-size: calc(5 / 6 * 1vw);
}

// Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use
//    the `inherit` value on things like `<th>` elements.

body {
  margin: 0; // 1
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  background-color: $body-bg; // 2
  text-align: left; // 3
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
}

//
// Typography
//

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
// stylelint-disable-next-line selector-list-comma-newline-after
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

// Reset margins on paragraphs
p {
  margin-top: 0;
  margin-bottom: 0;
}
