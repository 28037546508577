@import '../../../assets/scss/invisible.scss';

.fromTitle {
  background-image: linear-gradient(180deg, rgba(42, 43, 57, 0) 0%, #232534 100%);
  height: rem(170px);
  padding-top: rem(30px);
}

.inner {
  display: flex;
  align-items: center;
  padding-left: rem(50px);
  padding-right: rem(50px);
}

.decoration {
  min-height: 1px;
  height: rem(1px);
  background: linear-gradient(to right, rgba($white, 0.7) 80%, rgba($white, 0) 100%);
  flex-grow: 1;

  &.left {
    background: linear-gradient(to left, rgba($white, 0.7) 80%, rgba($white, 0) 100%);
  }

  &.right {
    margin-right: 18%;
    flex-grow: 0;
    flex-basis: 18%;
  }
}

.wording {
  padding-left: rem(23px);
  padding-right: rem(23px);
  font-weight: 400;
  font-size: rem(35px);
}

.emphasis {
  font-weight: 700;
}
