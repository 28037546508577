@import '../../../assets/scss/invisible.scss';

.shoutOutMain {
  display: flex;
  flex-flow: column;
  font-size: rem(92px);
  font-weight: 700;
}

.title {
  font-size: rem(92px);
  font-weight: 700;
}

.highlight {
  color: $red-700;
}

.nameHolder {
  width: 100%;
  height: rem(465px);
  background: $primary-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: rem(190px);
  font-weight: 900;
  line-height: 0.95;
}

.name {
  width: 100%;
  height: 100%;
  align-items: center;
}

.reason {
  line-height: rem(85px);
}
