//
// Update Loader component after changing the fonts here
//

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../../fonts/Barlow/Barlow-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: block;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../../fonts/Barlow/Barlow-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../../fonts/Barlow/Barlow-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../../fonts/Barlow/Barlow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../../fonts/Barlow/Barlow-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../../fonts/Barlow/Barlow-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-display: block;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../../fonts/Barlow/Barlow-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: block;
}
